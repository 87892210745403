<template>
  <div class="login">
    <div class="login-container">
      <div class="login-container-title">
        GTA—高岭教学辅助平台
      </div>
      <div class="login-content">
        <el-form :model="loginForm" label-width="70px" size="medium ">
          <el-form-item label="考试码">
            <el-input v-model="loginForm.loginCode" placeholder="请输入考生考试码" @keyup.enter.native="handleLogin" />
          </el-form-item>
        </el-form>
        <el-button
          class="login-btn"
          type="primary"
          :loading="submitting"
          :disabled="dialogVisible"
          @click="handleLogin"
        >
          登录
        </el-button>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="400px" title="请监考教师输入密码" top="30vh">
      <el-input v-model="form.password" type="password" @keyup.enter.native="handleLogin" />
      <div slot="footer">
        <el-button
          type="primary"
          style="margin: 0 auto"
          :loading="submitting"
          :disabled="form.password.length === 0"
          @click="handleLogin"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        loginCode: ''
      },
      form: {
        number: '',
        password: ''
      },
      dialogVisible: false,
      submitting: false
    }
  },
  methods: {
    handleLogin() {
      if (this.submitting) {
        return
      }

      if (this.loginForm.loginCode === '') {
        this.$message({
          message: '请输入登陆码',
          type: 'warning'
        })

        return
      }

      this.submitting = true
      this.$store.dispatch('login', this.loginForm)
        .then(() => {
          this.submitting = false
          this.$router.replace({
            path: '/information'
          })
        })
        .catch(({ message, code }) => {
          this.submitting = false
          this.$message.warning(message ?? '登录失败')

          if (+code === 4000) {
            this.dialogVisible = true
          }
        })
    }
  }
}
</script>

<style scoped >
.login {
  width: 100%;
}

.login-container {
  width: 1200px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container-title {
  font-size: 4vw;
  font-weight: bolder;
  color: #3979F3;
  font-family: seratUltra;
}

.login-content {
  width: 500px;
  height: 250px;
  margin-top: 50px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
}

span {
  width: fit-content;
}

.seat-input {
  font-size: 0.7vw;
  width: 90%;
}

.seat-input > .el-input__inner {
  height: 3vw;
}

.login-btn {
  margin-top: 30px;
  width: 100%;
  font-size: 0.7vw;
}

</style>
